<template>
  <div>
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="gray pt-4" v-if="!loading">
        <div class="container">
          <h3>Promote Your Post</h3>
          <h4 class="mb-0">{{ property.name }}</h4>
          <h5>
            <sub class="text-muted">KES {{ property.amount }}</sub>
          </h5>
          <div class="card mt-4">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="text-center">
                    <tr>
                      <th width="30%"></th>
                      <th
                        v-for="(price, i) in pricing"
                        :key="`thead-${i}`"
                        :class="`${
                          i == 1 ? 'basic' : i == 2 ? 'platinum' : 'standard'
                        }-pr`"
                        :width="`${70 / pricing.length}%`"
                      >
                        <div class="pricing-header">
                          <h4 class="pr-value" v-if="price.amount > 0">
                            <sup>KES</sup>{{ price.amount }}
                          </h4>
                          <h4 class="pr-value" v-if="price.amount == 0">
                            Free
                          </h4>
                          <h4 class="pr-title">{{ price.title }}</h4>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(line, i) in content_lines" :key="`line-${i}`">
                      <td>{{ line }}</td>
                      <td
                        class="text-center"
                        v-for="(price, j) in pricing"
                        :key="`line-${j}`"
                      >
                        {{ price.content[line] }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td
                        class="text-center"
                        v-for="(price, j) in pricing"
                        :key="`line-${j}`"
                      >
                        <a
                          href="#"
                          @click.prevent="submit(price)"
                          class="btn btn-outline-success"
                          >Select</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <loading v-if="loading" />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      pricing: [],
      content_lines: [],
      property: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/properties/${this.$route.params.id}/promote`)
        .then((response) => {
          this.pricing = response.data.pricing;
          this.content_lines = Object.keys(response.data.pricing[0].content);
          this.property = response.data.property;
          this.loading = false;
        });
    },

    submit(price) {
      this.loading = true;

      this.$axios
        .post(`/api/v1/properties/${this.$route.params.id}/promote`, {
          pricing_id: price.id,
        })
        .then((response) => {
          if (response.data.pay) {
            this.$router.push({ name: "payment", params: { id: response.data.account }});
          } else {
            this.$router.push({
              name: "my-listings.show",
              params: { slug: response.data.slug },
            });
          }

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
